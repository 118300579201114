import React from 'react';
import { Feedback } from './Feedback';
import store from 'client/store';
import './feedback-popup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export class FeedbackPopup extends React.Component {
	nodeItem = null;
	constructor(props) {
		super(props);
	}

	closePopup = () => {
		store.ui.showFeedbackPopup = false;
	};

	render() {
		return (
			<div className='wrapper-popup-feedback'>
				<div className='wrapper-feedback-form' ref={this.onMount}>
					<div className='wrapper-close-popup' onClick={this.closePopup}>
						<FontAwesomeIcon icon={faXmark} />
					</div>
					<div className='content'>
						<Feedback />
					</div>
				</div>
			</div>
		);
	}
}

