import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Desktop, NotPhone, Phone } from 'components';
import logoMini from 'client/img/logo-mini.svg?url';
import logoLight from 'client/img/logo-light.svg?url';
import logoDark from 'client/img/logo-dark.svg?url';
import { Text } from '@consta/uikit/Text';
import { Billing } from './Billing';
import store from 'client/store';
import './Header.scss';
import MobileAccountMenu from './mobile/MobileAccountMenu';
import { observable } from 'mobx';
import { GlobarSearch } from './GlobalSearch/GlobalSearch';
import { UserWithContextMenu } from './UserWithContextMenu/UserWithContextMenu';
import { OnlineUsers } from './OnlineUsers/OnlineUsers';
import { BackButton } from './BackButton/BackButton';
@observer
export default class Header extends React.Component {
	@observable showAccountMenu = false;
	refUserSection = null;

	constructor(props) {
		super(props);
		store.ui.menu = store.local.menuState;
	}

	toggleMenu = () => {
		if (store.local.menuState) {
			store.local.menuState = false;
			store.ui.menu = store.local.menuState;
		} else {
			store.local.menuState = true;
			store.ui.menu = store.local.menuState;
		}
	};

	goToBack = () => (typeof store.ui.backRoute === 'function' ? store.ui.backRoute() : store.route.push(store.ui.backRoute));

	toggleAccountMenu = () => {
		this.showAccountMenu = !this.showAccountMenu;
	};

	closeAccountMenu = () => {
		this.showAccountMenu = false;
	};

	logOut = () => {
		this.closeAccountMenu();
		store.model.logout();
	};

	handleClickOutUserSection = (event) => {
		if (this.refUserSection && !this.refUserSection.contains(event.target)) {
			this.closeAccountMenu();
		}
	};

	componentDidMount() {
		window.addEventListener('mousedown', this.handleClickOutUserSection);
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleClickOutUserSection);
	}

	onMountUserSection = (el) => {
		this.refUserSection = el;
	};

	render() {
		return (
			<header className='Header'>
				<div className={store.ui.menu ? 'header' : 'header header-minimized'}>
					<div className='mobile-menu' onClick={this.toggleMenu}>
						<FontAwesomeIcon icon={faBars} />
					</div>
					<div className={`title ${store.ui.menu ? 'visible' : ''}`}>
						<div className='logo'>
							<Link to='/'>
								<Phone>
									<img src={'/api' + logoMini} />
								</Phone>
								<NotPhone>
									{store.local.isDarkMode ? <img src={'/api' + logoDark} /> : <img src={'/api' + logoLight} />}
								</NotPhone>
							</Link>
						</div>
					</div>
				</div>
				<div className='toolbar'>
					<Desktop>
						<div className='toolbar-left'>
							{store.ui.backRoute && <BackButton onClick={this.goToBack} view='ghost' />}
							<Text>{store.ui.title || ''}</Text>
						</div>
					</Desktop>
					{store.model.isAuthorized && (
						<>
							<NotPhone>
								<div className='toolbar-right'>
									<GlobarSearch />
									<div />
									<div className='end'>
										<OnlineUsers />
										<Billing />
										<UserWithContextMenu />
									</div>
								</div>
							</NotPhone>
							<Phone>
								<MobileAccountMenu />
							</Phone>
						</>
					)}
				</div>
			</header>
		);
	}
}

