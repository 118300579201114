import { TextField } from '@consta/uikit/TextField';
import SearchIcon from '@phosphor-icons/core/regular/magnifying-glass.svg';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './i18n';

@withTranslation('components', { keyPrefix: 'SearchIconInput' })
export class SearchIconInput extends React.Component {
	render() {
		let { iconPosition = 'left', withClearButton = true, placeholder, t, ...restProps } = this.props;
		const icon = SearchIcon;

		const iconProps = {};
		if (iconPosition === 'right') {
			iconProps.rightSide = icon;
		} else if (iconPosition === 'left') {
			iconProps.leftSide = icon;
		}
		if (!placeholder) {
			placeholder = t('placeholder');
		}
		return <TextField withClearButton={withClearButton} placeholder={placeholder} {...iconProps} {...restProps} />;
	}
}
