import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { ThemeSwitcher } from '@features/ThemeSwitcher';

@observer
export default class Footer extends React.Component {
	render() {
		if (!store.ui.menu) {
			return (
				<div className='footer footer-minimized'>
					<div className='theme'>
						<ThemeToggle />
					</div>
				</div>
			);
		} else {
			return (
				<div className='footer'>
					<div className='theme'>
						<span>{t('selectTheme')}</span>
						<ThemeSwitcher />
					</div>
					<div className='copyright'>
						<div className='copyright-version'>
							{VERSION} ({BUILD})
						</div>
						<div className='copyright-bot'>© SmartUnit LLC {new Date().getFullYear()}</div>
					</div>
				</div>
			);
		}
	}
}

