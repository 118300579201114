import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';

import './categoryList.scss';
import { Column, Pager, Select, Table } from '@smartplatform/ui';
import CategoryTools from '@pages/sales/catalog/category-list/CategoryTools';
import store from 'client/store';
import { COUNTS_PER_PAGE } from '@pages/sales/catalog/constants';

@inject('productListStore')
@observer
export class CategoryList extends Component {
	constructor(props) {
		super(props);
		this.store = props.productListStore;
	}

	renderNumber = (record) => {
		const { categories } = this.store;
		return categories.findIndex((item) => item.id === record.id) + 1;
	};

	renderTools = (record) => {
		return <CategoryTools category={record} />;
	};

	onRowClick = (record) => {
		store.route.push({
			path: `${store.route.path}`,
			params: {
				categoryParentId: record.id,
			},
		});
	};

	onChange = (prop) => (value) => {
		this.store[prop] = value;
	};

	render() {
		const { categories, page, perPage } = this.store;
		const sliceValue = (page - 1) * perPage;

		if (!categories.length) return null;

		return (
			<div className='wrapper-categories-catalog'>
				<Table rows={categories.slice(sliceValue, sliceValue + perPage)} className='table-categories' onRowClick={this.onRowClick}>
					<Column label='№' computed={this.renderNumber} width={30} className='column-number' />
					<Column label={t('name')} property='name' />
					<Column width={50} computed={this.renderTools} className='column-tools' />
				</Table>
				<div className='wrapper-bottom-categories-catalog'>
					<div className='wrapper-select-showBy'>
						<span>{t('catalog.showBy')}</span>
						<Select value={perPage} items={COUNTS_PER_PAGE} onChange={this.onChange('perPage')} isRequired />
					</div>
					{categories.length > perPage && (
						<Pager current={page} totalCount={categories.length} onChange={this.onChange('page')} itemsPerPage={20} noCount />
					)}
				</div>
			</div>
		);
	}
}

