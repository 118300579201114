import { Redirect } from 'react-router-dom';
import React from 'react';
import store from '@appStore';
import { observer } from 'mobx-react';

export const RequireAuth = observer(({ children }) => {
	if (!store.model?.isAuthorized) {
		return <Redirect to='/auth' />;
	}

	return children;
});
