import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import './globarSearch.scss';
import { debounce, lowerFirst } from 'lodash';
import store from 'client/store';
import t from 'i18n';
import { BoxLabel, User } from 'components';
import { RenewAbortController } from 'client/tools';
import { Select } from '@consta/uikit/Select';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { SearchIconInput } from '@features/SearchIconInput';
import { Popover } from '@consta/uikit/Popover';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

const AVAILABLE_MODULES = ['management', 'sales', 'documents', 'manufacture', 'admin', 'contragents'];

const MODELS = {
	Task: {
		path: '/management/tasks/',
		color: '#006996',
		renderItem: (r) => r.name,
	},
	Project: {
		path: '/management/projects/',
		color: '#850016',
		renderItem: (r) => r.name,
	},

	Order: {
		path: '/sales/orders/',
		color: '#266d57',
		renderItem: (r) => r.description,
	},
	Document: {
		path: '/documents/documents/',
		color: '#cc9900',
		renderItem: (r) => r.name,
	},
	ProductionOrder: {
		path: '/manufacture/production-orders/',
		color: '#03055b',
		renderItem: (r) => r.name,
	},
	User: {
		path: '/admin/users/',
		color: '#ff00ff',
		renderItem: (r) => <User user={new store.model.User(r)} full />,
	},
	Contragent: {
		path: '/contragents/contragents/',
		color: '#09071a',
		renderItem: (r) => r.fullName || r.name,
	},
};

@observer
export class GlobarSearch extends React.Component {
	@observable search = '';
	@observable showResult = false;
	@observable searchResult = [];
	@observable isLoading = true;
	abortController = new RenewAbortController();
	@observable filterModule = { label: t('globalSearch.all'), id: null };

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.availableModules = store.availableModules.filter((module) => AVAILABLE_MODULES.includes(module.code));
		this.moduleList = [
			{ label: t('globalSearch.all'), id: null },
			...this.availableModules.map(({ code }, index) => ({ code, label: t('module.' + code + '.title'), id: index + 1 })),
		];
	}

	onSearch = (value) => {
		this.search = value;
		if (!value.trim()) {
			this.showResult = false;
			this.searchResult = [];
		} else this.doSearch();
	};

	doSearch = async () => {
		const search = this.search.trim();
		this.showResult = true;

		if (search) {
			try {
				this.isLoading = true;
				this.abortController.abort();
				let modules = this.availableModules.map((i) => i.code);
				if (this.filterModule.id) {
					modules = [this.filterModule.code];
				}
				const params = { search, modules };
				const res = await fetch(`/api/global-search?params=${encodeURIComponent(JSON.stringify(params))}`, {
					signal: this.abortController.signal,
				});

				this.searchResult = await res.json();
			} catch (e) {
				console.error(e);
			}
			this.isLoading = false;
		}
	};

	onResultItemClick = (item) => {
		this.showResult = false;
		this.searchResult = false;
		this.search = '';
		const path = MODELS[item.model]?.path;
		path && store.route.push({ path: `${path}${item.id}` });
	};

	onInputClick = () => {
		if (this.search.length > 0 && this.searchResult.length > 0) {
			this.showResult = true;
		}
	};
	filterModuleSelect = (value) => {
		this.filterModule = value;
		// триггер фетча при другом значении
		this.onSearch(this.search);
	};

	onActivateFilterModuleSelect = () => {
		this.showResult = false;
	};

	onClickOutside = () => (this.showResult = false);
	onDropdownOpen = () => (this.showResult = false);

	render() {
		const {
			search,
			onSearch,
			showResult,
			searchResult,
			ref,
			onResultItemClick,
			onInputClick,
			isLoading,
			filterModuleSelect,
			filterModule,
			onClickOutside,
			onDropdownOpen,
		} = this;

		const noItems = <div className='not-found'>{t('globalSearch.notFound')}</div>;

		const listItems = isLoading ? (
			<Loader size='s' />
		) : (
			<div className='popover-list'>
				{searchResult?.length > 0
					? searchResult.map((item, index) => {
							const labelColor = MODELS[item.model]?.color;
							const label = MODELS[item.model].renderItem(item.record);
							return (
								<div key={index} onClick={() => onResultItemClick(item)} className='popover-item'>
									<Text truncate>{label}</Text>
									<BoxLabel label={{ name: t(`${lowerFirst(item.model)}.title`), color: labelColor }} className='model' />
								</div>
							);
					  })
					: noItems}
			</div>
		);

		return (
			<div className='GlobarSearch' ref={ref}>
				<FieldGroup>
					<Select
						items={this.moduleList}
						placeholder={t('globalSearch.all')}
						empty={t('globalSearch.all')}
						value={filterModule}
						onChange={filterModuleSelect}
						disabled={this.availableModules.length < 2}
						view='ghost'
						onDropdownOpen={onDropdownOpen}
					/>
					<SearchIconInput
						position='left'
						placeholder={t('globalSearch.placeholder')}
						value={search}
						onChange={onSearch}
						onClick={onInputClick}
						view='ghost'
					/>
				</FieldGroup>
				{showResult && search?.length > 0 && (
					<Popover
						direction='downCenter'
						className='GlobarSearch-popover SelectDropdown-ScrollContainer'
						isInteractive={true}
						anchorRef={ref}
						offset='2xs'
						onClickOutside={onClickOutside}
						equalAnchorWidth
					>
						{listItems}
					</Popover>
				)}
			</div>
		);
	}
}

