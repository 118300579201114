import React from 'react';
import { ColorUtils } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const BoxLabel = ({ label, style, closeCallback }) => {
	if (!label) return null;
	const { r, g, b } = ColorUtils.hexToRgb(label.color);

	return (
		<div className='box-label-full' style={{ color: 'white', backgroundColor: `rgba(${r},${g},${b},0.8)` }}>
			{label.name}
			{closeCallback && <FontAwesomeIcon icon={faTimes} onClick={closeCallback} className='close-btn' />}
		</div>
	);
};

