import en from './en.json';
import ru from './ru.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	fallbackLng: 'ru',
	debug: true,
	resources: { en, ru },
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});

export default function (key, options) {
	return i18n.t(key, options);
}

export const i18nInstance = i18n;
