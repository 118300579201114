import store from '@appStore';
import React from 'react';
import { fio } from '@smartplatform/consta/lib/fio';
import t from 'i18n';
import { observer } from 'mobx-react';
import { AvatarGroup } from '@consta/uikit/AvatarGroup';
import { Text } from '@consta/uikit/Text';
import './OnlineUsers.scss';

@observer
export class OnlineUsers extends React.Component {
	render() {
		const onlineUsers = [...store.onlineUsersMap.values()].reverse();
		if (onlineUsers.length === 0) return null;
		const avatarGroupItems = onlineUsers.map((user) => ({
			name: fio(user),
			url: user.avatar ? user.downloadFile('avatar') + `?image=[{"resize":{"width":50,"height":50}}]&` + user.avatar : null,
		}));
		return (
			<div className='OnlineUsers'>
				<Text className='online'>{t('online')}</Text>
				<AvatarGroup items={avatarGroupItems} visibleCount={20} size='s' />
			</div>
		);
	}
}
