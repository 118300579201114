import React from 'react';
import { Provider, observer } from 'mobx-react';
import { Toolbar, Breadcrumbs } from 'components';
import { ProductList } from './product-list';
import { productListStore } from './store';
import { Suggestions } from './suggestions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { CategoryList } from './category-list';
import store from 'client/store';
import t from 'i18n';
import './catalog.scss';

@observer
export class Catalog extends React.Component {
	store;

	constructor(props) {
		super(props);
		this.store = new productListStore({ path: props.match.path });
	}

	componentDidMount() {
		const { categoryParentId } = store.route.params || {};
		if (categoryParentId) {
			this.store.paramsCategoryId = parseInt(categoryParentId);
		}
		this.store.init();
	}

	componentDidUpdate(prevProps, prevState) {
		let { categoryParentId } = store.route.params || {};
		categoryParentId = parseInt(categoryParentId) || null;
		if (this.store.paramsCategoryId !== categoryParentId) {
			this.store.paramsCategoryId = categoryParentId;
		}
	}

	onCreateProduct = () => {
		const params = {};
		if (this.store.paramsCategoryId) params.categoryParentId = this.store.paramsCategoryId;
		store.route.push({ path: `${this.store.path}/product/create`, params });
	};

	onCreateCategory = () => {
		const params = {};
		if (this.store.paramsCategoryId) params.categoryParentId = this.store.paramsCategoryId;
		store.route.push({ path: `${this.store.path}/category/create`, params });
	};

	render() {
		const { products, onSearch, searchValue, onFocus, onBlur, onClearCategory, isInitialized, exportCatalog } = this.store;

		return (
			<Provider productListStore={this.store}>
				<div className='pricelist-catalog'>
					<div className='pricelist-catalog__right'>
						<Toolbar>
							<Toolbar.CreateButton label={t('product.new')} onClick={this.onCreateProduct} />
							<Toolbar.CreateButton label={t('category.new')} onClick={this.onCreateCategory} />
							<Toolbar.ExportBtn text={t('export')} onClick={() => exportCatalog(this.store.paramsCategoryId)} />
							<Suggestions>
								<Toolbar.SearchOld
									onSearch={onSearch}
									value={searchValue}
									onFocus={onFocus}
									onBlur={onBlur}
									endIcon={searchValue.length > 0 && <FontAwesomeIcon icon={faXmark} onClick={onClearCategory} />}
								/>
							</Suggestions>

							<Toolbar.Count count={products.length} />
						</Toolbar>
						{isInitialized && (
							<>
								{this.store.categoriesMap.size > 0 ? (
									<Breadcrumbs crumbs={[...this.store.breadcrumpsItems]} />
								) : (
									<div className='warn-message'>{t('product.addCategories')}</div>
								)}
								<CategoryList />
								<ProductList />
							</>
						)}
					</div>
				</div>
			</Provider>
		);
	}
}

