import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import store from '@appStore';
import './ThemeSwitcher.scss';

@observer
export class ThemeSwitcher extends React.Component {
	@observable isDarkMode = store.local.isDarkMode || false;

	switchTheme = (e) => {
		if (this.isDarkMode) {
			this.isDarkMode = false;
			document.body.className = 'default-theme';
		} else {
			this.isDarkMode = true;
			document.body.className = 'dark-theme';
		}

		store.local.isDarkMode = this.isDarkMode;

		// переключатель темы консты
		store.setThemePreset();
	};

	render() {
		return (
			<div className={classNames('ThemeSwitcher', { dark: this.isDarkMode })} onClick={this.switchTheme}>
				<div className={classNames('wrapper-toggle-themes', { dark: this.isDarkMode })}>
					<div className='toggle-themes'></div>
				</div>
				<div className='sun'>
					<FontAwesomeIcon icon={faSun} />
				</div>
				<div className='moon'>
					<FontAwesomeIcon icon={faMoon} />
				</div>
			</div>
		);
	}
}
