import { Responses500 } from '@consta/uikit/Responses500';
import { Text } from '@consta/uikit/Text';
import { ToRootButton } from './ToRootButton';
import './errorFallback.css';

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
	console.error(error);
	return (
		<Responses500
			className='ErrorFallback'
			actions={
				<div className='actions'>
					<Text truncate view='alert'>
						{error.message}
					</Text>

					<ToRootButton onClick={resetErrorBoundary} />
				</div>
			}
		/>
	);
};
