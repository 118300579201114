import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
import { SingleDialog } from '@pages/messages/dialogs/single';
import { Dialogs } from '@pages/messages/dialogs/dialogs';

export function Dialog({ match }) {
	const { path } = match;

	return (
		<Switch>
			<Route path={`${path}`} exact component={withTitleAndLink(Dialogs, { title: t('dialog.plural') })} />
			<Route
				path={`${path}/:id`}
				exact
				component={withTitleAndLink(SingleDialog, { title: t('dialog.plural'), backRoute: { path: path } })}
			/>
		</Switch>
	);
}

