import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import store from 'client/store';
import { PlusIconButton } from '@features/PlusIconButton';

@observer
export class ToolbarCreateButton extends React.Component {
	static propTypes = {
		url: PropTypes.string,
	};

	create = () => store.route.push({ path: this.props.url });

	render() {
		return <PlusIconButton label={this.props.label} onClick={this.create} className='toolbar-create-btn' {...this.props} />;
	}
}

