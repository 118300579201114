import { DndContext } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import './styles/main.scss';
// не убирать! после билда необходимо чтобы favicon оставалась в статике
import favicon from 'client/img/favicon.ico?url';
import { AppRouter } from './AppRouter';
import { ConstaTheme } from './hocs/ConstaTheme';
import { Responses503 } from '@consta/uikit/Responses503';
import { ErrorFallback } from '@widgets/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import store from '@appStore';

const App = () => {
	if (store.model.error && !store.model.error.code) return <Responses503 actions={<div></div>} />;
	if (!store.isInitialized) return null;

	// как уберем smartplatform/ui можно убрать и DndContext
	return (
		<ConstaTheme>
			<DndContext>
				<ErrorBoundary fallbackRender={ErrorFallback}>
					<AppRouter key={store.appKey} />
				</ErrorBoundary>
			</DndContext>
		</ConstaTheme>
	);
};

export default observer(App);
