import React from 'react';
import { observer, Provider } from 'mobx-react';
import { DialogsStore } from '@pages/messages/dialogs/dialogs/dialogsStore';
import { MODES } from '@shared/constants';
import { ListDialogs } from '@pages/messages/dialogs/dialogs/list';
import { KanbanDialogs } from '@pages/messages/dialogs/dialogs/kanban';
import { DialogFilters } from '@pages/messages/dialogs/dialogs/filters';
import t from 'i18n';
import './style.scss';
import store from 'client/store';
import { triggerInfoNotification } from 'components';

@observer
export class Dialogs extends React.Component {
	constructor(props) {
		super(props);
		this.store = new DialogsStore();
	}

	componentDidMount = () => {
		store.socket.on('DialogsUpdate', async () => {
			await this.store.fetchRecords();
			triggerInfoNotification(t('record.recordsUpdated'));
		});
	};

	componentWillUnmount = () => {
		store.socket.off('DialogsUpdate');
	};

	render() {
		const { mode } = this.store;

		const components = {
			[MODES.LIST]: <ListDialogs />,
			[MODES.KANBAN]: <KanbanDialogs />,
		};

		const component = components[mode] || components[MODES.LIST];

		return (
			<Provider store={this.store}>
				<div className='dialogs-root'>
					<h2 className='head-page'>{t('dialog.plural')}</h2>
					<DialogFilters />
					{component}
				</div>
			</Provider>
		);
	}
}

