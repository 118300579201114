import React from 'react';
import { Switch } from 'react-router';
import { withTitleAndLink } from 'client/tools/hoc/withTitleAndLink';
import t from 'i18n';
import { Route } from 'react-router-dom';
import { MESSAGES_DIALOG_PATH, MESSAGES_SETTINGS_PATH } from '@pages/messages/constants';
import { Dialog } from '@pages/messages/dialogs';
import { SettingTabs } from '@pages/messages/settings';

export default () => (
	<Switch>
		<Route path={MESSAGES_DIALOG_PATH} component={withTitleAndLink(Dialog, { title: t('dialog.plural') })} />
		<Route path={MESSAGES_SETTINGS_PATH} component={withTitleAndLink(SettingTabs, { title: t('settings') })} />
	</Switch>
);

