import List from './icons/mode-list.svg';
import Kanban from './icons/mode-kanban.svg';
import Tiles from './icons/mode-tiles.svg';
import Calendar from './icons/mode-calendar.svg';
import Report from './icons/mode-report.svg';
import Gantt from './icons/mode-gantt.svg';
import { Button } from '@consta/uikit/Button';
import { MODES } from '@shared/constants';
import './ModeSwitcher.css';

const MODES_ICONS = {
	[MODES.KANBAN]: Kanban,
	[MODES.LIST]: List,
	[MODES.TILES]: Tiles,
	[MODES.CALENDAR]: Calendar,
	[MODES.REPORT]: Report,
	[MODES.GANTT]: Gantt,
};

export const ModeSwitcher = ({ modes, onChange, selected }) => {
	return (
		<div className='ModeSwitcher'>
			{modes.map((mode) => {
				if (MODES_ICONS[mode]) {
					return (
						<Button
							view={selected === mode ? 'primary' : 'secondary'}
							key={mode}
							onClick={() => onChange(mode)}
							onlyIcon
							iconLeft={MODES_ICONS[mode]}
						/>
					);
				}
			})}
		</div>
	);
};
