import React from 'react';
import { observer } from 'mobx-react';
import ModuleMenu from './ModuleMenu';
import store from '@appStore';
import './menu.scss';

@observer
export default class Menu extends React.Component {
	render() {
		const modulesMenu = [];
		for (const module of store.availableModules) {
			modulesMenu.push(<ModuleMenu key={module.code} module={module} />);
		}

		return (
			<div className='menu' ref={this.onMount}>
				{modulesMenu}
			</div>
		);
	}
}

