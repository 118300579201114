import React from 'react';
import { MessagesWidgetStore } from '@pages/messages/dialogs/components/messages-widget/store';
import { AutoAnimate, Loader } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { PostMessage } from 'components/post-message';
import { Message } from '@pages/messages/dialogs/components/message/Message';
import './style.scss';
import store from 'client/store';
@observer
export class MessagesWidget extends React.Component {
	constructor(props) {
		super(props);
		this.store = new MessagesWidgetStore(this.props.record, this.props.relation);
	}

	renderMessage = (record, key) => {
		const messageProps = {};
		if (this.props.noOwnerAvatar) messageProps.noOwnerAvatar = this.props.noOwnerAvatar;
		return <Message record={record} onIntersection={() => this.store.onIntersection(record)} key={key} {...messageProps} />;
	};

	componentDidMount = () => {
		store.socket.on(`${this.props.record.MODEL.INFO.name}:${this.props.record.id}`, () => this.store.fetchMessages());
	};

	componentWillUnmount = () => {
		store.socket.off(`${this.props.record.MODEL.INFO.name}:${this.props.record.id}`);
	};

	onSubmit = async (message) => {
		if (this.props.onSubmit) await this.props.onSubmit(message);
		this.store.onSubmitComment();
	};

	render() {
		const { isLoadingMessage, messagesOnMount, record, scrollBottom, messages } = this.store;
		return (
			<div className='wrapper-widget-messages'>
				<AutoAnimate className='wrapper-messages' ref={messagesOnMount}>
					{isLoadingMessage ? <Loader size={20} /> : messages.map((record, index) => this.renderMessage(record, index))}
				</AutoAnimate>
				<PostMessage record={record} className='post-message' onSubmit={this.onSubmit} onToggleEdit={scrollBottom} />
			</div>
		);
	}
}

