import { observable } from 'mobx';
import store from 'client/store';
import { DEFAULT_PRIORITY, MODES } from '@shared/constants';
import { RenewAbortController } from 'client/tools';
import { MESSAGES_DIALOG_PATH } from '@pages/messages/constants';

export class DialogsStore {
	@observable isInit = false;
	@observable source = null;
	@observable hideSpam = false;
	@observable itemsSource = [];
	@observable mode = MODES.LIST;
	@observable statuses = [];
	@observable hiddenListsKanban = [];
	@observable kanbanInit = false;
	constructor() {
		this.init();
	}

	init = async () => {
		const sources = await store.model.DialogSource.find();
		this.itemsSource = sources.map((r) => ({ label: r.title, value: r.id }));
		await this.fetchRecords();
		this.isInit = true;
	};

	fetchRecords = async () => {
		const fetchFunctions = {
			[MODES.KANBAN]: this.fetchKanban,
		};
		if (fetchFunctions[this.mode]) fetchFunctions[this.mode]();
	};

	onChange = (prop) => async (value) => {
		if (this.hasOwnProperty(prop)) this[prop] = value;
		await this.fetchRecords();
	};

	onChangeMode = async (mode) => {
		this.mode = mode;
		await this.fetchRecords();
	};

	get whereFilter() {
		const where = { and: [] };

		if (this.source) where.and.push({ sourceId: { eq: this.source } });
		if (this.hideSpam) where.and.push({ or: [{ isSpam: { eq: false } }, { isSpam: { eq: null } }] });

		return where;
	}

	fetchKanbanAbortController = new RenewAbortController();

	fetchKanban = async () => {
		this.fetchKanbanAbortController.abort();
		this.statuses = await store.model.ViewDialogStatus.find({
			signal: this.fetchKanbanAbortController.signal,
			include: [
				{
					relation: 'dialogs',
					scope: {
						include: ['source'],
						where: this.whereFilter,
						order: 'priority desc',
					},
				},
			],
		});
		this.kanbanInit = true;
		this.reloadKanban();
	};

	getKanbanInstance = (instance) => (this.kanban = instance);
	reloadKanban = () => this.kanban?.reload && setTimeout(() => this.kanban.reload());

	onChangeKanban = async ({ item, text, prev, next, list }) => {
		this.saving = true;

		const dialog = new store.model.Dialog(item);

		dialog.statusId = list.id;
		if (!item) {
			dialog.name = text;
		}

		if (!prev) {
			dialog.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		} else if (!next) {
			dialog.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		} else {
			dialog.priority = (prev.priority + next.priority) / 2;
		}

		try {
			await dialog.save();
		} catch (e) {
			throw e;
		}

		return {
			id: dialog.id,
			name: dialog.name,
			path: `${MESSAGES_DIALOG_PATH}/${dialog.id}`,
			data: item ?? dialog,
		};
	};

	gotoItemKanban = (path) => store.route.push({ path });
}

