import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import store from 'client/store';
import { Ripple, BadgeTitle } from 'components';

@observer
export default class MenuItem extends React.Component {
	@observable badgeCount;

	constructor(props) {
		super(props);
		this.init();
	}

	init() {
		// Если указано событие по которому нужно обновлять badge подписываемся на него
		// когда это событие срабатывает, то вызываем обновление коунтера
		if (this.props.badgeEvent) {
			store.socket.on(this.props.badgeEvent, (data) => {
				this.update();
			});
		}
		this.update();
	}

	isActive = (match) => {
		if (match) {
			// так как нельзя менять состояние в момент рендера, откладываем выполнение
			setTimeout(() => {
				store.ui.activeSubMenu = this.props.parentCode;
				this.props.changeVisibility(true);
			}, 0);
		}
		return !!match;
	};

	/**
	 * Обновляем bangeCount через вызов badgeFunc
	 */
	update = async () => {
		if (this.props.badgeFunc) {
			this.badgeCount = await this.props.badgeFunc(store);
		}
	};

	saveItemPathToStore = () => {
		store.local.submenu[this.props.parentCode] = true;
		store.local.lastPath = this.props.item.path;
	};

	handleItemClick = () => {
		if (window.innerWidth <= 767 && store.ui.menu) {
			store.local.menuState = false;
			store.ui.menu = false;
		}
		this.saveItemPathToStore();
	};

	render() {
		const { item } = this.props;
		const { label, path, icon, reactIcon } = item;
		if (item.enabled && !item.enabled(store)) return null;

		const className = classNames('item');
		let renderIcon = <span className='no-icon' />;
		if (icon) renderIcon = <FontAwesomeIcon icon={icon} />;
		if (reactIcon) renderIcon = reactIcon;

		return (
			<NavLink
				to={path}
				className={store.ui.menu ? className : className + ' minimised'}
				activeClassName='active'
				isActive={this.isActive}
				onClick={this.handleItemClick}
			>
				<span className={store.ui.menu ? 'icon' : 'icon icon-minimised'}>{renderIcon}</span>
				{store.ui.menu ? this.badgeCount ? <BadgeTitle content={label} badge={this.badgeCount} /> : label : ''}
				{typeof label === 'string' && <Ripple />}
			</NavLink>
		);
	}
}
