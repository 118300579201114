import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ToolbarCreateButton } from './ToolbarCreateButton';
import { ToolbarExportBtn } from './ToolbarExportBtn';
import { ToolbarImportBtn } from './ToolbarImportBtn';
import { ToolbarCount } from './ToolbarCount';
import { ToolbarSearch } from './ToolbarSearch';
import { ToolbarUserSelect } from './ToolbarUserSelect';
import { ToolbarButtonGroup } from './ToolbarButtonGroup';
import { ToolbarListSelect } from './ToolbarListSelect';
import { ToolbarSortSelect } from './ToolbarSortSelect';
import { ToolbarCleanButton } from './ToolbarCleanButton';
import { ToolbarProjectSelect } from './ToolbarProjectSelect';
import { ToolbarItemsSelect } from './ToolbarItemsSelect';
import { ToolbarTop } from './ToolbarTop';
import { ToolbarRightSide } from './ToolbarRightSide';
import { ToolbarLeftSide } from './ToolbarLeftSide';
import { ToolbarBottom } from './ToolbarBottom';
import { ToolbarManySelect } from 'components/toolbar/ToolbarManySelect';
import './toolbar.scss';
import { ToolbarPrevNextSwitcher } from 'components/toolbar/ToolbarPrevNextSwitcher';
import { ToolbarAvatarGroup } from './ToolbarAvatarGroup';
import { ToolbarSearchOld } from './ToolbarSearchOld';

export const Toolbar = ({ children, className }) => <div className={classNames('composite-toolbar', className)}>{children}</div>;

Toolbar.CreateButton = ToolbarCreateButton;
Toolbar.ExportBtn = ToolbarExportBtn;
Toolbar.ImportBtn = ToolbarImportBtn;
Toolbar.Count = ToolbarCount;
Toolbar.Search = ToolbarSearch;
Toolbar.SearchOld = ToolbarSearchOld;
Toolbar.AvatarGroup = ToolbarAvatarGroup;
Toolbar.UserSelect = ToolbarUserSelect;
Toolbar.ButtonGroup = ToolbarButtonGroup;
Toolbar.ListSelect = ToolbarListSelect;
Toolbar.SortSelect = ToolbarSortSelect;
Toolbar.CleanButton = ToolbarCleanButton;
Toolbar.ProjectSelect = ToolbarProjectSelect;
Toolbar.ItemsSelect = ToolbarItemsSelect;
Toolbar.Top = ToolbarTop;
Toolbar.Bottom = ToolbarBottom;
Toolbar.RightSide = ToolbarRightSide;
Toolbar.LeftSide = ToolbarLeftSide;
Toolbar.ManySelect = ToolbarManySelect;
Toolbar.PrevNextSwitcher = ToolbarPrevNextSwitcher;
