import React from 'react';
import { DocumentFormStore } from '@pages/messages/dialogs/single/category-erp/document/documentFormStore';
import { AttachButton, Attachments, DatePicker, Form, Section } from 'components';
import { Field, Input, Loader, RelationSelect, Row } from '@smartplatform/ui';
import t from 'i18n';
import { renderPlaceholder, renderStatus } from 'client/tools';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { CustomAttachment } from 'components';
import { Link } from 'react-router-dom';
import { DOCUMENTS_PATH } from '@pages/documents/constants';

@inject('store')
@observer
export class DocumentForm extends React.Component {
	constructor(props) {
		super(props);
		this.store = new DocumentFormStore(props.record, props.dialog);
	}

	render() {
		const { isLoading, document, beforeSave } = this.store;
		const { onCancel } = this.props;

		if (isLoading) return <Loader size={20} />;

		const disabledSave = !document.type || !document.startDate || !document.number || !document.status;
		const disabledForm = document.id;

		return (
			<Section title={document.id ? <Link to={`${DOCUMENTS_PATH}/${document.id}`}>{t('document.title')}</Link> : t('document.title')}>
				<Form
					record={document}
					noDelete
					stay
					onCancel={onCancel}
					noCancel={disabledForm}
					noSave={disabledForm}
					beforeSave={beforeSave}
					disableSave={disabledSave}
				>
					<Row>
						<Field relation='type' property='name' label={t('document.card.type')} isRequired disabled={disabledForm}>
							<RelationSelect empty={renderPlaceholder(t('select'))} />
						</Field>
						<Field property='startDate' label={t('document.card.startDate')} isRequired disabled={disabledForm}>
							<DatePicker placeholder={t('datePickerPlaceholder')} format='dd.MM.yyyy' />
						</Field>
					</Row>
					<Row>
						<Field property='endDate' label={t('document.card.endDate')} disabled={disabledForm}>
							<DatePicker placeholder={t('datePickerPlaceholder')} format='dd.MM.yyyy' />
						</Field>
						<Field property='number' label={t('document.card.number')} isRequired disabled={disabledForm}>
							<Input placeholder={t('document.card.addNumber')} />
						</Field>
					</Row>
					<Row>
						<Field property='total' label={t('document.card.totalSum')} disabled={disabledForm}>
							<Input placeholder={t('document.card.addSum')} />
						</Field>
						<Field
							relation='status'
							computed={renderStatus}
							label={t('document.card.status')}
							isRequired
							disabled={disabledForm}
						>
							<RelationSelect empty={renderPlaceholder(t('select'))} />
						</Field>
					</Row>
					<div className={classNames('attachment-files', { disabled: disabledForm })}>
						<Attachments
							RenderCustomAttachment={CustomAttachment}
							renderCustomAttachmentButton={<AttachButton />}
							record={document}
							canUpload={true}
							softDelete={true}
							archiveAttachments={false}
							withFormContext={true}
						/>
					</div>
				</Form>
			</Section>
		);
	}
}

