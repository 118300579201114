import React from 'react';
import Root from './Root';
import t from 'i18n';
import { MESSAGES_DIALOG_PATH, MESSAGES_SETTINGS_PATH } from '@pages/messages/constants';
import { faGear, faMessage } from '@fortawesome/free-solid-svg-icons';
import { BadgeTitle } from 'components';

export default {
	/** Заголовок модуля */
	title: <BadgeTitle type='beta' content={<strong>{t('messages.title')}</strong>} />,

	/** Короткий заголовок модуля */
	titleShort: t('messages.title'),

	/** Код модуля на латинице, обязателен */
	code: 'messages',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Dialog && store.model.Dialog.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	menu: () => {
		return [
			{
				type: 'item',
				label: t('dialog.plural'),
				path: MESSAGES_DIALOG_PATH,
				icon: faMessage,
				badgeFunc: async (store) =>
					await store.model.ViewComment.count({
						dialogId: { neq: null },
						or: [{ hasRead: false }, { hasRead: null }],
					}),
				badgeEvent: 'DialogMessageCount',
			},
			{
				type: 'item',
				label: t('settings'),
				path: MESSAGES_SETTINGS_PATH,
				icon: faGear,
				enabled: (store) =>
					(store.model.DialogSource && store.model.DialogSource.INFO.WRITE) ||
					(store.model.DialogStatus && store.model.DialogStatus.INFO.WRITE),
			},
		];
	},
};

