import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactDatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import t from 'i18n';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';
import InputMask from 'react-input-mask';
import store from '@appStore';

const LOCALES = { ru, en };
@observer
export class DatePicker extends React.Component {
	static propTypes = {};
	static defaultProps = {
		dateFormat: 'dd.MM.yyyy',
	};

	render() {
		const { className, value, onChange, dateFormat, onClose, ...restProps } = this.props;

		return (
			<ReactDatePicker
				isClearable
				showIcon
				icon={
					<svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M13.8 2.60001H2.6C1.71634 2.60001 1 3.31635 1 4.20001V15.4C1 16.2837 1.71634 17 2.6 17H13.8C14.6837 17 15.4 16.2837 15.4 15.4V4.20001C15.4 3.31635 14.6837 2.60001 13.8 2.60001Z'
							stroke='#323232'
							strokeWidth='1.6'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path d='M11.4004 1V4.2' stroke='#323232' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M5 1V4.2' stroke='#323232' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
						<path d='M1 7.39999H15.4' stroke='#323232' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				}
				placeholderText={t('datePickerPlaceholder')}
				dateFormat={dateFormat}
				onChange={onChange}
				customInput={
					<InputMask mask='99.99.9999' value={value} onChange={onChange}>
						{(inputProps) => <input {...inputProps} type='text' />}
					</InputMask>
				}
				{...restProps}
				selected={value ? new Date(value) : null}
				locale={LOCALES[store.local.language || 'ru']} // локализации
				timeCaption={t('time')} // метка времени
			/>
		);
	}
}

