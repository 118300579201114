import { MODES } from '@shared/constants';

export const localStoreConfig = {
	menuState: true,
	isDarkMode: false,
	operations: {},
	analytics: {},
	contragents: { search: '' },
	nomenclatures: { search: '' },
	nomenclatureMovements: { search: '' },
	agGrid: {
		leftSidebar: false,
		theme: 'quartz',
		floatingFilter: false,
	},
	documents: {
		mode: MODES.LIST,
	},
	production: {
		mode: MODES.KANBAN,
	},
	projects: {
		mode: MODES.LIST,
		projectLists: [],
		order: 'updatedAt desc',
		user: 'ME',
		closed: null,
	},
	project: {
		IsLogsSortedByNew: false,
	},
	tasks: {
		boardLists: [],
		closed: false,
		order: 'updatedAt desc nulls last',
		projects: [],
		search: '',
		page: 1,
		user: 'ME',
		owner: 'ALL',
	},
	task: {
		isTaskLogsSortedByNew: false,
	},
	document: {
		isDocumentLogsSortedByNew: false,
	},
	orders: {
		mode: MODES.KANBAN,
	},
	order: {
		isOrderLogsSortedByNew: false,
	},
	submenu: {
		admin: true,
		contragents: false,
		documents: false,
		finance: false,
		management: true,
		manufacture: false,
		messages: false,
		sales: false,
		storehouse: false,
	},
	projectTasks: {
		showFilters: false,
		tasksKanbanOrder: 'priority desc',
		mode: '',
	},
	dashboardMyTasks: {},
	dashboardMyOrders: {},
	dashboardMyProductionOrders: {},
	socialAuth: {
		state: null,
	},
	lastPath: null,
};
