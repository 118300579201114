export const SALES_PATH = '/sales';
export const ORDERS_PATH = `${SALES_PATH}/orders`;
export const ORDERS_REPORT_PATH = `${SALES_PATH}/ordersreport`;
export const PRICE_CATEGORIES_PATH = `${SALES_PATH}/pricecategories`;
export const SETTINGS_PATH = `${SALES_PATH}/settings`;
export const CATALOG_PATH = `${SALES_PATH}/catalog`;
export const SALES_REPORTS_PATH = `${SALES_PATH}/reports`;
export const SALES_DASHBOARD_PATH = `${SALES_PATH}/dashboard`;
export const DEFAULT_CATALOG_IMAGE_SIZE = 5;
export const MIN_CATALOG_IMAGE_SIZE = 3;
export const MAX_CATALOG_IMAGE_SIZE = 20;
export const STEP_CATALOG_IMAGE_SIZE = 1;
