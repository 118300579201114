import { autorun, observable } from 'mobx';
import store from 'client/store';
import { DEFAULT_CATALOG_IMAGE_SIZE } from '@pages/sales/constants';

const SETTINGS = [
	'defaultWarehouse',
	'showOldPriceList',
	'showOrderPassport',
	'showOrderWaybill',
	'showOrderOldPositions',
	'showOrderTechMap',
	'showOrderCustomFields',
	'catalogMaxSizeImage',
];

class salesStore {
	@observable isInitialized = false;
	@observable moduleServerConfig = {};
	@observable showOldPriceList = false;
	@observable showOrderPassport = false;
	@observable showOrderWaybill = false;
	@observable showOrderOldPositions = false;
	@observable showOrderTechMap = false;
	@observable showOrderCustomFields = false;
	@observable defaultWarehouse = null;
	@observable catalogMaxSizeImage = DEFAULT_CATALOG_IMAGE_SIZE;

	constructor() {
		this.init();
	}

	init = () => {
		this.moduleServerConfig = store.model.config.modules.find((module) => module?.name === 'sales') || {};
		const salesConfig = store.mergedConfig.modules.find((module) => module?.name === 'sales') || {};

		Object.entries(salesConfig).forEach(([key, value]) => {
			if (SETTINGS.includes(key)) {
				value = ['false', 'true'].includes(value) ? Boolean(value) : value;
				if (this[key] !== undefined) {
					this[key] = value;
				}
			}
		});

		this.isInitialized = true;
	};
}

export default new salesStore();
