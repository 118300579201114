import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import store from '@appStore';
import './i18n';

export const ToRootButton = (props) => {
	const { t } = useTranslation('components', { keyPrefix: 'ToRootButton' });

	const onClick = () => {
		props.onClick?.();
		store.route.push({ path: '/' });
	};
	return <Button {...props} view='ghost' label={t('toMain')} onClick={onClick} />;
};
