export const DEFAULT_PRIORITY = 1000;
export const KANBAN_INITIAL_LIMIT = 20;
export const TABLE_ROW_HEIGHT = 48;
export const FILTER = 'FILTER';
export const ALL = 'ALL';
export const MAIN_DASHBOARD_PATH = '/main-dashboard';
export const PERIODS = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	QUARTER: 'quarter',
	YEAR: 'year',
};
