import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Editor } from 'components';
import store from 'client/store';
import t from 'i18n';
import './feedback.scss';
import { fioShort } from 'client/tools';
import { ErrorMessage } from '@shared/ui/ErrorMessage';
import RefreshBtn from 'client/img/refresh.svg';

@observer
export class Feedback extends Component {
	@observable isSend = false;
	@observable isLoading = false;
	@observable attachmentIds = [];
	@observable captcha = '';
	@observable reloads = 0;

	@observable fio = null;
	@observable email = null;
	@observable subject = null;
	@observable description = null;
	@observable errors = {
		fio: null,
		email: null,
		subject: null,
		description: null,
		captcha: null,
	};
	constructor(props) {
		super(props);
		this.time = new Date().getTime();
		this.elements = {};
		this.init();
	}

	init = () => {
		if (store.model.isAuthorized) {
			this.fio = fioShort(store.model.user);
			this.email = store.model.user.email;
		}
	};

	onMount = (name) => (el) => (this.elements[name] = el);
	focus = (name) => this.elements[name] && this.elements[name].focus();

	refresh = () => this.reloads++;

	onEditorInit = (editor) => {
		this.editor = editor;
	};

	handleEnd = (error, result, media, success, failure) => {
		if (error) {
			console.warn('upload error:', error);
			failure(error.message);
		} else {
			const url = 'cid:' + `${media.id}-${media.filename}`;
			this.attachmentIds.push(media.id);
			success(url);
		}
	};

	imagesUploadHandler = (blobInfo, success, failure) => {
		const file = blobInfo.blob();
		const filename = blobInfo.filename();
		const media = new store.model.Attachment();

		media.filename = filename;
		media.inline = true;
		media.save().then(() => {
			media
				.uploadFile(media.filename, file)
				.on('progress', (event) => {})
				.end((error, result) => this.handleEnd(error, result, media, success, failure));
		});
	};

	onChange = (fieldName) => (e) => (this[fieldName] = e.target.value);

	onEditorChange = (value) => (this.description = value);

	resetErrors = () => {
		Object.keys(this.errors).forEach((key) => {
			this.errors[key] = null;
		});
	};

	submit = async () => {
		this.resetErrors();

		const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

		if (!EMAIL_REGEXP.test(this.email)) {
			this.errors.email = t('feedback.invalidEmail');
			this.focus('email');
			return false;
		}

		this.isLoading = true;

		try {
			const feedback = await store.model.Feedback.create({
				fio: this.fio,
				email: this.email,
				subject: this.subject,
				description: this.description,
				captcha: this.captcha,
			});
			await feedback.sendMessage();
			this.isSend = true;
		} catch (e) {
			if (['INVALID_CAPTCHA', 'EMPTY_CAPTCHA'].includes(e.code)) {
				this.errors.captcha = t(e.code);
				this.focus('captcha');
			}
			this.captcha = '';
		}
		this.reloads++;
		this.isLoading = false;
	};

	render() {
		let disableSave = !this.fio || !this.email || !this.subject || !this.description || this.isLoading;
		if (!store.model.isAuthorized) disableSave = disableSave || !this.captcha;

		return (
			<>
				{!this.isSend ? (
					<>
						<form action='submit' className='auth-form'>
							{!store.model.isAuthorized && (
								<div className='field'>
									<label className='required'>{t('fio')}</label>
									<input type='text' name='fio' value={this.fio} onChange={this.onChange('fio')} />
									<ErrorMessage field={this.errors.fio} />
								</div>
							)}
							{!store.model.isAuthorized && (
								<div className='field'>
									<label className='required'>{t('feedback.email')}</label>
									<input
										type='text'
										name='email'
										value={this.email}
										onChange={this.onChange('email')}
										ref={this.onMount('email')}
									/>
									<ErrorMessage field={this.errors.email} />
								</div>
							)}
							<div className='field'>
								<label className='required'>{t('feedback.subject')}</label>
								<input type='text' name='subject' value={this.subject} onChange={this.onChange('subject')} />
								<ErrorMessage field={this.errors.subject} />
							</div>
							<div className='field'>
								<label className='required'>{t('feedback.description')}</label>
								<Editor
									value={this.description}
									onChange={this.onEditorChange}
									onInit={this.onEditorInit}
									automaticUploads={false}
									autoResize
									height={250}
									menubar={false}
									toolbar='undo redo | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | link image'
									imagesUploadHandler={this.imagesUploadHandler}
								/>
								<ErrorMessage field={this.errors.description} />
							</div>
							{!store.model.isAuthorized && (
								<>
									<div className='field'>
										<label className='required'>{t('auth.captcha')}</label>
										<input
											type='text'
											name='captcha'
											value={this.captcha}
											onChange={this.onChange('captcha')}
											ref={this.onMount('captcha')}
										/>
										<ErrorMessage field={this.errors.captcha} />
									</div>
									<div className='captcha'>
										<img src={`/api/captcha?new-${this.time}-${this.reloads}`} ref={(el) => (this.img = el)} alt='' />
										<RefreshBtn className='reload' onClick={this.refresh} />
									</div>
								</>
							)}
							<div className='submits'>
								<Button text={t('feedback.send')} variant='primary' onClick={this.submit} disabled={disableSave} />
							</div>
						</form>
					</>
				) : (
					<div className='feedback-notice'>{t('feedback.sent')}</div>
				)}
			</>
		);
	}
}

