import React from 'react';
import { inject, observer } from 'mobx-react';
import { OrderFormStore } from '@pages/messages/dialogs/single/category-erp/order/orderFormStore';
import { Field, Loader, RelationSelect, Row } from '@smartplatform/ui';
import { AttachButton, Attachments, Button, CancelButton, Form, Section, UserSelectOld } from 'components';
import t from 'i18n';
import { renderPlaceholder, renderStatus } from 'client/tools';
import store from 'client/store';
import classNames from 'classnames';
import { CustomAttachment } from 'components';
import { Link } from 'react-router-dom';
import { ORDERS_PATH } from '@pages/sales/constants';

@inject('store')
@observer
export class OrderForm extends React.Component {
	constructor(props) {
		super(props);
		this.store = new OrderFormStore(props.record, props.dialog);
	}

	render() {
		const { isLoading, order, beforeSave } = this.store;
		const { onCancel } = this.props;

		if (isLoading) return <Loader size={20} />;

		const disabledForm = order.id;
		const disabledSave = !order.list || !order.manager;

		return (
			<Section title={order.id ? <Link to={`${ORDERS_PATH}/${order.id}`}>{t('order.title')}</Link> : t('order.title')}>
				<Form
					record={order}
					noDelete
					stay
					onCancel={onCancel}
					noCancel={disabledForm}
					noSave={disabledForm}
					beforeSave={beforeSave}
					disableSave={disabledSave}
				>
					<Row>
						<Field
							relation='list'
							property='name'
							label={t('order.status')}
							computed={renderStatus}
							isRequired
							disabled={disabledForm}
						>
							<RelationSelect
								model={store.model.OrderList}
								filter={{ where: store.model.OrderList.INFO.WRITE ? undefined : { id: { inq: availableListIds } } }}
								empty={renderPlaceholder(t('select'))}
								isRequired
							/>
						</Field>
						<Field relation='manager' label={t('order.manager')} isRequired disabled={disabledForm}>
							<UserSelectOld />
						</Field>
					</Row>
					<Field property='comment' label={t('order.comment')} disabled={disabledForm}>
						<textarea value={this.comment} onChange={this.onChangeComment} placeholder={t('postComment.placeholder')} />
					</Field>
					<div className={classNames('attachment-files', { disabled: disabledForm })}>
						<Attachments
							RenderCustomAttachment={CustomAttachment}
							renderCustomAttachmentButton={<AttachButton />}
							record={order}
							canUpload={true}
							softDelete={true}
							archiveAttachments={false}
							withFormContext={true}
						/>
					</div>
				</Form>
			</Section>
		);
	}
}

