import store from 'client/store';
import { ERROR, INFO, SUCCESS, WARNING } from './constants';
import React from 'react';
import t from 'i18n';
import { fio } from 'client/tools';
import { Avatar } from '@smartplatform/ui';
import { MANAGEMENT_MANAGEMENT_TASKS_PATH } from '@pages/constants';

export const triggerLogInNotification = (user) =>
	user &&
	store.ui.setNotification({
		soundCode: 'online',
		message: (
			<>
				<Avatar className='avatar' user={user} size={28} />
				<div>{fio(user)}</div>
				{t('notification.socket.connectedUser')}
			</>
		),
		type: INFO,
	});

export const triggerInfoNotification = (message) => store.ui.setNotification({ message, type: INFO });
export const triggerTaskNotification = (task) => {
	const { path } = store.route;
	const taskPath = `${MANAGEMENT_MANAGEMENT_TASKS_PATH}/${task.id}`;
	const isSamePath = path === taskPath;
	const message = isSamePath ? (
		t('task.notification.youResponsibleThisTask')
	) : (
		<div>
			<div>{t('task.notification.youResponsible')}</div> <div>{task.name}</div>
		</div>
	);

	const onConfirm = isSamePath ? undefined : () => store.route.push({ path: taskPath });
	store.ui.setNotification({ message, soundCode: 'task', type: SUCCESS, onConfirm });
};

export const triggerTaskMention = (task) => {
	const { path } = store.route;
	const taskPath = `${MANAGEMENT_MANAGEMENT_TASKS_PATH}/${task.id}`;
	const isSamePath = path === taskPath;
	const message = isSamePath ? (
		t('task.notification.youMentionThisTask')
	) : (
		<div>
			<div>{t('task.notification.youMention')}</div> <div>{task.name}</div>
		</div>
	);

	const onConfirm = isSamePath ? undefined : () => store.route.push({ path: taskPath });
	store.ui.setNotification({ message, soundCode: 'task', type: SUCCESS, onConfirm });
};

export const triggerSaveNotification = (message = t('record.saved')) => store.ui.setNotification({ message, type: SUCCESS });
export const triggerDeleteNotification = (message = t('record.deleted')) => store.ui.setNotification({ message, type: WARNING });
export const triggerErrorNotification = (errorMessage) =>
	store.ui.setNotification({
		message: (
			<div>
				{t('error')}: {errorMessage}
			</div>
		),
		type: ERROR,
	});

export const triggerWarningNotification = (warningMessage) =>
	store.ui.setNotification({
		message: <div>{warningMessage}</div>,
		type: WARNING,
	});
