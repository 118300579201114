import { ContextMenu } from '@consta/uikit/ContextMenu';
import { UserAvatar } from '@smartplatform/consta/ui/UserAvatar';
import React from 'react';
import store from '@appStore';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';

@observer
export class UserWithContextMenu extends React.Component {
	@observable isUserContextOpen = false;
	ref = React.createRef();
	videoUrl = store.model.config?.videoLessonsURL || 'https://www.youtube.com/@smart-erp_pro/videos';

	goTo = (path) => () => store.route.push({ path });
	openUserContext = () => (this.isUserContextOpen = true);
	closeUserContext = () => (this.isUserContextOpen = false);
	openVideo = () => window.open(this.videoUrl, '_blank');
	logout = () => store.model.logout();
	toggleFeedbackPopup = () => {
		store.ui.showFeedbackPopup = !store.ui.showFeedbackPopup;
	};
	onUserContextSelect = (item) => {
		this.closeUserContext();
		item.onClick();
	};

	userContextItems = [
		{
			label: t('user.profile'),
			onClick: this.goTo('/profile'),
		},
		{
			label: t('video'),
			onClick: this.openVideo,
		},
		{
			label: t('support'),
			onClick: this.toggleFeedbackPopup,
		},
		{
			label: t('logout'),
			accent: 'alert',
			onClick: this.logout,
		},
	].filter(Boolean);

	render() {
		const { openUserContext, ref, isUserContextOpen, userContextItems, closeUserContext, onUserContextSelect } = this;

		return (
			<div className='UserWithContextMenu'>
				<UserAvatar ref={ref} user={store.model.user} onClick={openUserContext} withArrow fioShort />
				<ContextMenu
					isOpen={isUserContextOpen}
					items={userContextItems}
					getItemStatus={(item) => item.accent}
					anchorRef={ref}
					direction='downStartRight'
					onClickOutside={closeUserContext}
					getItemOnClick={onUserContextSelect}
				/>
			</div>
		);
	}
}
