import React from 'react';
import { observer } from 'mobx-react';
import { Mobile } from 'components';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import store from 'client/store';
import './style.scss';
import { FeedbackPopup } from '@features/feedback';

@observer
export class MainLayout extends React.Component {
	onPageMount = (el) => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
		}
	};

	onScroll = (e) => {
		window.dispatchEvent(new CustomEvent('myscroll', { detail: { scrollTop: e.target.scrollTop } }));
	};

	render() {
		return (
			<div className='layout'>
				<Header />
				{store.ui.showFeedbackPopup && <FeedbackPopup />}
				<div className='layout-content'>
					<Sidebar />
					<div className='page' ref={this.onPageMount}>
						{store.ui.title && (
							<Mobile>
								<div className='page-title'>{store.ui.title || ''}</div>
							</Mobile>
						)}
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

