import store from '@appStore';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Notifications } from 'components';
import { getAvailableModules } from 'client/tools';
import loadable from '@loadable/component';
import { RequireAuth } from './hocs/RequireAuth';
import { AuthLayout, MainLayout } from './layouts';
import { RewritePassword } from 'components/rewrite-password';

const AuthPage = loadable(() => import('@pages/auth'));
const Profile = loadable(() => import('components/profile'));
const Test = loadable(() => import('@pages/test'));
const TwoFactorPage = loadable(() => import('@pages/2fa'));

export const AppRouter = () => {
	// пользователю нужно отдать только страницу с формой восстановления пароля
	const _passwordExpirationTime = parseInt(store.mergedConfig?.authentication?.password?.passwordExpirationTime);
	const enableRewrite = !!_passwordExpirationTime;
	if (enableRewrite && (!store.model.user.passwordTimeout || new Date() > new Date(store.model.user.passwordTimeout))) {
		return (
			<Router history={store.history}>
				<AuthLayout>
					<div className='auth'>
						<Route path='/' exact>
							<Redirect to='/rewrite-password' />
						</Route>
						<Route path='/rewrite-password' component={RewritePassword} />
						<Redirect from='/*' to='/rewrite-password' />
					</div>
				</AuthLayout>
			</Router>
		);
	}

	// отдаём пользователю осталное приложение
	const modulesRoutes = [];
	store.availableModules = getAvailableModules();
	for (let module of store.availableModules) {
		const Module = module.component;
		modulesRoutes.push(<Module key={module.code} />);
	}
	
	return (
		<Router history={store.history}>
			<Notifications />
			<Switch>
				{/* 2FA **/}
				<Route
					path='/2fa/:type/:token'
					render={(props) => (
						<AuthLayout>
							<TwoFactorPage {...props} />
						</AuthLayout>
					)}
				/>

				{/* Auth **/}
				<Route path='/auth'>
					<AuthLayout>
						<Route path='/auth' component={AuthPage} />
					</AuthLayout>
				</Route>
				{/* Main app pages **/}
				<Route path='/'>
					<RequireAuth>
						<MainLayout>
							{modulesRoutes}
							<Switch>
								<Route path='/' exact>
									<Redirect to='/management/tasks' />
								</Route>
								<Route path='/profile' component={Profile} />
								<Route path='/test' component={Test} />
							</Switch>
						</MainLayout>
					</RequireAuth>
				</Route>
			</Switch>
		</Router>
	);
};
